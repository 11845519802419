import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material'
import React from 'react'
import { BoxWithLabel } from '../../../../common/components/BoxWithLabel'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { PatientListItemType } from '../types/patientList.types'
import { useSendMessageToPatients } from '../hooks/useSendMessageToPatients'
import { useNotifications } from '../../../../common/providers'
import { useTranslation } from '../../../../common/hooks'

interface SendMessageToPatientsModalForm {
  content: string
  isEmail: boolean
}

interface SendMessageToPatientsModalProps {
  isOpen: boolean
  selectedPatients: PatientListItemType[]
  clearSelectedPatients: () => void
  toggleIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SendMessageToPatientsModal: React.FC<
  React.PropsWithChildren<SendMessageToPatientsModalProps>
> = ({ selectedPatients, clearSelectedPatients, isOpen, toggleIsOpen }) => {
  const { t } = useTranslation('patients')
  const { setSuccessToast } = useNotifications()

  const [sendMessageToPatients] = useSendMessageToPatients()

  const formMethods = useForm<SendMessageToPatientsModalForm>({})
  const { register, handleSubmit, watch, control } = formMethods

  const onClose = () => {
    toggleIsOpen(false)
  }

  const onSubmit = handleSubmit(async (formData) => {
    await sendMessageToPatients({
      variables: {
        recipientUserIds: selectedPatients.map(
          (patient) => patient.user?.id ?? ''
        ),
        message: formData.content,
        alsoAsEmail: !!formData.isEmail,
      },
      onCompleted: () => {
        setSuccessToast('patients.message.success')
        clearSelectedPatients()
        onClose()
      },
    })
  })

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <FormProvider {...formMethods}>
        <DialogTitle>{t('bulk.message.title')}</DialogTitle>
        <DialogContent>
          <BoxWithLabel label={t('bulk.message.content')}>
            <TextField
              fullWidth
              multiline
              rows={8}
              placeholder={t('bulk.message.content')}
              {...register('content')}
            ></TextField>
          </BoxWithLabel>

          <Controller
            render={({ field }) => (
              <FormControlLabel
                label={t('bulk.message.isEmail')}
                control={<Checkbox {...field} checked={!!watch('isEmail')} />}
              />
            )}
            name={'isEmail'}
            control={control}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button color="error" variant="contained" onClick={onSubmit}>
            {t('bulk.message.send')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}

export { SendMessageToPatientsModal }
