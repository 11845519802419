import { Button } from '@mui/material'
import { AddBoxOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { AddProtocolModal } from './AddProtocolModal'
import { AddProtocolInput } from '../../../../../models/graphqlTypes'
import { Treatment } from '../../types/treatments.types'
import { useProtocolCreate } from '../../hooks/useProtocolCreate'
import { useNotifications } from '../../../../../common/providers'

interface AddProtocolButtonProps {
  treatment: Treatment
  refetchTreatment: () => void
}
export const AddProtocolButton: React.FC<AddProtocolButtonProps> = ({
  treatment,
  refetchTreatment,
}) => {
  const { t } = useTranslation()
  const { setSuccessToast } = useNotifications()

  const [isAddProtocolModalOpen, toggleAddProtocolModalOpen] = useState(false)

  const [addProtocol, { loading }] = useProtocolCreate({
    onCompleted: () => {
      setSuccessToast('appointment:creationMessage.success', {
        useFullKey: true,
      })
      toggleAddProtocolModalOpen(false)
      refetchTreatment()
    },
  })

  const onSubmit = (treatmentData: Omit<AddProtocolInput, 'treatmentId'>) => {
    addProtocol({
      variables: {
        treatmentData: { ...treatmentData, treatmentId: treatment.id },
      },
    })
  }

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        startIcon={<AddBoxOutlined />}
        onClick={() => toggleAddProtocolModalOpen(true)}
        sx={{ paddingX: 2 }}
      >
        {t('treatment:addNewProtocol')}
      </Button>

      {isAddProtocolModalOpen && (
        <AddProtocolModal
          isOpen={isAddProtocolModalOpen}
          onSave={onSubmit}
          onClose={() => toggleAddProtocolModalOpen(false)}
          treatmentBnoCodes={treatment.bnoCodes}
          loading={loading}
        />
      )}
    </>
  )
}
