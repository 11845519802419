import { useQuery } from '@apollo/client'

import {
  getReceivedSurveysForAssistant,
  getReceivedSurveysForAssistantVariables,
  getReceivedSurveysForDoctor,
  getReceivedSurveysForDoctorVariables,
  UserType,
} from '../../../../models/graphqlTypes'
import {
  RECEIVED_SURVEYS_FOR_ASSISTANT,
  RECEIVED_SURVEYS_FOR_DOCTOR,
} from '../../../../operations/dashboardOperations'
import { DoctorPatientSurvey } from '../../../../views/doctor/Treatments/types/surveys.types'
import { useUserType } from '../../../hooks'
import { getSurveyListReturn } from '../dashboard.utils'

type Options = {
  variables: Pick<
    getReceivedSurveysForAssistantVariables,
    'filterData' | 'paginationInput'
  >
}

type ReturnData = {
  loading: boolean
  surveys: DoctorPatientSurvey[]
  totalSurveys: number
  hasMoreSurveys: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreSurveys: (options: Options) => void
}

export const useReceivedSurveysForDoctorOrAssistant = (
  options: Options
): ReturnData => {
  const userType = useUserType()
  const isDoctor = userType === UserType.Doctor

  const { data: doctorData, ...doctorResults } = useQuery<
    getReceivedSurveysForDoctor,
    getReceivedSurveysForDoctorVariables
  >(RECEIVED_SURVEYS_FOR_DOCTOR, {
    ...options,
    skip: !isDoctor,
  })

  const { data: assistantData, ...assistantResults } = useQuery<
    getReceivedSurveysForAssistant,
    getReceivedSurveysForAssistantVariables
  >(RECEIVED_SURVEYS_FOR_ASSISTANT, {
    ...options,
    skip: userType !== UserType.Assistant,
  })

  return isDoctor
    ? getSurveyListReturn(
        doctorResults,
        doctorData?.getReceivedSurveysForDoctor
      )
    : getSurveyListReturn(
        assistantResults,
        assistantData?.getReceivedSurveysForAssistant
      )
}
