import { useQuery } from '@apollo/client'
import { GET_ASSISTANT_DOCTORS } from '../../../operations'
import { getAssistantDoctors, UserType } from '../../../models/graphqlTypes'
import { useUserType } from '../../../common/hooks'

export type AssistantDoctor = NonNullable<
  getAssistantDoctors['getAssistantProfile']['ownDoctors']
>[0]

export const useAssistantDoctors = () => {
  const isAssistant = useUserType() === UserType.Assistant

  const {
    data: { getAssistantProfile: { ownDoctors: doctors = [] } = {} } = {},
    loading,
  } = useQuery<getAssistantDoctors, Record<string, unknown>>(
    GET_ASSISTANT_DOCTORS,
    {
      fetchPolicy: 'cache-and-network',
      skip: !isAssistant,
    }
  )

  return { doctors, loading }
}
