import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useColorPalette } from '../../../common/hooks/helper/useColor'
import { BetmenPage, Tab } from '../../../common/components/BetmenPage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { PatientAppointments } from './components/PatientAppointments'
import { PatientSurveys } from './components/PatientSurveys'
import { usePatientAppointmentCount } from './hooks/usePatientAppointmentCount'
import { usePatientIsSurveyExpireSoon } from './hooks/usePatientIsSurveyExpireSoon'
import { usePatientSurveyCount } from './hooks/usePatientSurveyCount'

enum TabName {
  Treatments = 'treatments',
  Surveys = 'surveys',
}

export const PatientEvents: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const history = useHistory()

  const [selectedTabName, setSelectedTabName] = useState<TabName>(
    history.location.pathname.split('/')[3] as TabName
  )

  const { data: appointments } = usePatientAppointmentCount()
  const appointmentCountByIsOwnAndInFuture =
    appointments?.appointmentCountByIsOwnAndInFuture

  const { data: surveys, refetch: refetchSurveyNumber } =
    usePatientSurveyCount()
  const surveyCountByIsOwnAndNotFinished =
    surveys?.surveyCountByIsOwnAndNotFinished

  const { data: isSurveyExpireSoon, refetch: refetchIsSurveyExpireSoon } =
    usePatientIsSurveyExpireSoon()
  const isSurveyExpireSoonByIsOwnAndNotFinished =
    !!isSurveyExpireSoon && isSurveyExpireSoon

  const tabs: Tab[] = useMemo(
    () => [
      {
        label: t('home:patient.appointments', {
          number: appointmentCountByIsOwnAndInFuture,
        }),
        value: 0,
        title: TabName.Treatments,
      },
      {
        label: t('home:patient.surveys', {
          number: surveyCountByIsOwnAndNotFinished,
        }),
        value: 1,
        title: TabName.Surveys,
      },
    ],
    [appointmentCountByIsOwnAndInFuture, surveyCountByIsOwnAndNotFinished, t]
  )

  const selectedTabIndex = useMemo(
    () => tabs.findIndex((tab) => tab.title === selectedTabName),
    [selectedTabName, tabs]
  )

  const changeTab = (tabIndex: number) => {
    const tabName = tabs[tabIndex].title as TabName
    if (!tabName) {
      return
    }
    setSelectedTabName(tabName)
    history.push(`/patient/home/${tabName}`)
  }

  useEffect(() => {
    setSelectedTabName(history.location.pathname.split('/')[3] as TabName)
    refetchSurveyNumber()
    refetchIsSurveyExpireSoon()
  }, [
    history.location.pathname,
    refetchIsSurveyExpireSoon,
    refetchSurveyNumber,
  ])

  return (
    <BetmenPage
      title={t('menu:patient.myTreatments')}
      tabs={tabs}
      defaultTabIndex={selectedTabIndex}
      selectedTabValue={selectedTabIndex}
      onTabChange={changeTab}
      withoutPadding
      extraHeaderContent={
        <>
          {!!surveyCountByIsOwnAndNotFinished && (
            <Box
              bgcolor={
                isSurveyExpireSoonByIsOwnAndNotFinished
                  ? colorPalette.error.light
                  : colorPalette.warning.main
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
                gap: 3,
                m: 2,
                px: 2,
                py: 0.5,
              }}
            >
              {isSurveyExpireSoonByIsOwnAndNotFinished ? (
                <>
                  <Typography variant="h6">
                    <Box component="span" fontWeight="bold" display="inline">
                      {t('survey:expireSoonWarningBold')}
                    </Box>{' '}
                    {t('survey:expireSoonWarning')}
                  </Typography>
                </>
              ) : (
                <>
                  <ContactSupportIcon fontSize="large" />
                  <Typography variant="h6">
                    {t('appointment:survey.numberOfSurveys', {
                      number: surveyCountByIsOwnAndNotFinished,
                    })}
                  </Typography>
                </>
              )}
              {selectedTabName !== TabName.Surveys && (
                <Button variant="outlined" onClick={() => changeTab(1)}>
                  {t('patients:survey.fillOut')}
                </Button>
              )}
            </Box>
          )}
        </>
      }
    >
      {selectedTabName === TabName.Treatments && <PatientAppointments />}
      {selectedTabName === TabName.Surveys && <PatientSurveys />}
    </BetmenPage>
  )
}
