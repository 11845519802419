import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { BnoCodeSelector } from '../../../../common/components/selectors/BnoCodeSelector'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { BnoCodeChore, treatmentChore } from '../../../../models/graphqlTypes'
import { FormProvider, useForm } from 'react-hook-form'
import { BoxWithLabel } from '../../../../common/components/BoxWithLabel'

export type AddTreatmentModalFormValues = {
  title: string
  description: string
  bnoCodes: BnoCodeChore[]
}

type AddTreatmentModalProps = {
  isOpen: boolean
  treatment?: treatmentChore
  onClose: () => void
  onSave: (formValues: AddTreatmentModalFormValues) => void
  loading?: boolean
}

const AddTreatmentModal: React.FC<
  React.PropsWithChildren<AddTreatmentModalProps>
> = (props) => {
  const { isOpen, treatment, onClose, onSave, loading } = props
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  const methods = useForm<AddTreatmentModalFormValues>({
    defaultValues: {
      title: treatment?.title?.[selectedLanguage] ?? '',
      description: treatment?.description?.[selectedLanguage] ?? '',
      bnoCodes: treatment?.bnoCodes ?? [],
    },
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = methods

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  useEffect(() => {
    reset({
      title: treatment?.title?.[selectedLanguage] ?? '',
      description: treatment?.description?.[selectedLanguage] ?? '',
      bnoCodes: treatment?.bnoCodes ?? [],
    })
  }, [reset, selectedLanguage, treatment])

  const onSubmit = handleSubmit(async (formValues) => {
    onSave(formValues)
  })

  return (
    <FormProvider {...methods}>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose()
          }
        }}
        fullWidth
      >
        <DialogTitle>{t('treatment:doctor.assignDialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={2}>
            {t('treatment:doctor.addSchemaDialogText')}
          </DialogContentText>
          <Box>
            <BoxWithLabel label={t('treatment:doctor.title')}>
              <TextField
                {...register('title', { required })}
                size="small"
                placeholder={t('treatment:doctor.title')}
                variant="outlined"
                error={!!errors.title}
                helperText={errors.title?.message}
                fullWidth
                data-cy="AddTreatmentModal-TextField-title"
              />
            </BoxWithLabel>
            <BnoCodeSelector
              name="bnoCodes"
              label={t('treatment:protocol.bnoCode')}
            />
            <BoxWithLabel label={t('treatment:doctor.description')}>
              <TextField
                {...register('description', { required })}
                size="small"
                multiline
                variant="outlined"
                maxRows={7}
                placeholder={t('treatment:doctor.description')}
                error={!!errors.description}
                helperText={errors.description?.message}
                minRows={7}
                fullWidth
                data-cy="AddTreatmentModal-TextField-description"
              />
            </BoxWithLabel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={loading}
            data-cy="AddTreatmentModal-Button-addTreatment"
          >
            {t('common:save')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export { AddTreatmentModal }
