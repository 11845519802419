import React, { useState } from 'react'
import { MessageIcon } from '../../../../../common/icons/MessageIcon'
import { Button } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks'
import { SendMessageToPatientsModal } from '../SendMessageToPatientsModal'
import { PatientListItemType } from '../../types/patientList.types'

interface SendMessageToPatientsButtonProps {
  selectedPatients: PatientListItemType[]
  clearSelectedIds: () => void
}
export const SendMessageToPatientsButton: React.FC<
  SendMessageToPatientsButtonProps
> = ({ selectedPatients, clearSelectedIds }) => {
  const { t } = useTranslation()
  const [isMessagePanelOpen, setMessagePanelOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="text"
        sx={{ paddingX: '16px' }}
        startIcon={<MessageIcon />}
        onClick={() => setMessagePanelOpen(true)}
      >
        {t('patients:actions.message')}
      </Button>

      {isMessagePanelOpen && (
        <SendMessageToPatientsModal
          isOpen={isMessagePanelOpen}
          toggleIsOpen={setMessagePanelOpen}
          selectedPatients={selectedPatients}
          clearSelectedPatients={clearSelectedIds}
        />
      )}
    </>
  )
}
