import React, { Dispatch, SetStateAction, useState } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Button } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks'
import { PatientListItemType } from '../../types/patientList.types'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components'
import { DeletePatientsResultModal } from '../DeletePatientsResultModal'
import { useDeleteDoctorOwnPatients } from '../../hooks/useDeleteDoctorOwnPatients'
import { UsePatientsListReturn } from '../../../../../common/hooks/usePatientsList'
import { deleteDoctorsOwnPatients as BulkDismissResult } from '../../../../../models/graphqlTypes'

interface DeletePatientsButtonProps
  extends Pick<UsePatientsListReturn, 'refetch'> {
  selectedPatients: PatientListItemType[]
  clearSelectedIds: () => void
  deletedPatients: BulkDismissResult | null
  setDeletedPatients: Dispatch<SetStateAction<BulkDismissResult | null>>
}
export const DeletePatientsButton: React.FC<DeletePatientsButtonProps> = ({
  selectedPatients,
  clearSelectedIds,
  refetch,
  deletedPatients,
  setDeletedPatients,
}) => {
  const { t } = useTranslation()

  const [confirmDeletePatients, setConfirmDeletePatients] =
    useState<ConfirmDialogValue>(false)
  const [deleteDoctorsOwnPatients] = useDeleteDoctorOwnPatients()

  const handleDelete = async () => {
    if (selectedPatients.length) {
      await deleteDoctorsOwnPatients({
        variables: {
          patientIds: selectedPatients.map((patient) => patient.id),
        },
        onCompleted: (deletedPatients: BulkDismissResult) => {
          setDeletedPatients(deletedPatients)
          refetch()
        },
      })
    }
  }

  const handleCloseDeletePatientsResultModal = () => {
    setDeletedPatients(null)
    clearSelectedIds()
  }

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="text"
        onClick={() => setConfirmDeletePatients(true)}
        sx={{ paddingX: '16px' }}
        startIcon={<ExitToAppIcon />}
      >
        {t('patients:actions.delete')}
      </Button>

      {confirmDeletePatients && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[confirmDeletePatients, setConfirmDeletePatients]}
          text={t(`patients:confirmDeletePatients`)}
          onAccept={() => handleDelete()}
        />
      )}

      {deletedPatients && (
        <DeletePatientsResultModal
          selectedPatients={selectedPatients}
          deletedPatients={deletedPatients}
          onClose={handleCloseDeletePatientsResultModal}
        />
      )}
    </>
  )
}
