import React from 'react'
import {
  Card,
  Grid,
  Stack,
  Box,
  IconButton,
  Typography,
  TypographyProps,
} from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { PatientSurveyResult } from '../../types/surveyStore.types'

const CARD_BG_COLOR = '#BFC7D9'

interface DetailRowProps extends TypographyProps {
  detailType: string
  detailValue: string
}

const DetailRow = ({ detailType, detailValue, ...props }: DetailRowProps) => {
  const { t } = useTranslation()
  return (
    <Grid container direction="row" my={1}>
      <Grid item xs={4}>
        <Typography variant="subtitle1">
          {t(`survey:result.detail.${detailType}`)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant={'body2'} {...props}>
          {detailValue}
        </Typography>
      </Grid>
    </Grid>
  )
}

interface Props {
  surveyResult: PatientSurveyResult
  hidePatientDetails: boolean
  setHidePatientDetails: React.Dispatch<React.SetStateAction<boolean>>
}

export const SurveyResultPatientDetails: React.FC<
  React.PropsWithChildren<Props>
> = ({ surveyResult, hidePatientDetails, setHidePatientDetails }) => {
  const { t } = useTranslation()

  return (
    <Card sx={{ backgroundColor: CARD_BG_COLOR, p: 3, mb: 2 }}>
      <Stack flexDirection="row">
        {hidePatientDetails ? (
          <Stack flex={1} justifyContent="center">
            <Typography variant="h6">
              {t(`survey:result.detail.patientData`)}
            </Typography>
          </Stack>
        ) : (
          <Stack flex={1} flexDirection={{ xs: 'column', sm: 'row' }}>
            <Stack flexDirection="column" flex={1}>
              <DetailRow
                detailType="recipient"
                detailValue={t('common:formattedNameFull', {
                  title: surveyResult.recipientProfile.title,
                  firstName: surveyResult.recipientProfile.firstName,
                  lastName: surveyResult.recipientProfile.lastName,
                })}
                variant="h6"
              />
              <DetailRow
                detailType="patient"
                detailValue={
                  surveyResult.patient
                    ? `${t(
                        'common:formattedNameFull',
                        surveyResult.patient
                      )} (${surveyResult.patient.tajNumber})`
                    : '-'
                }
              />
              <DetailRow
                detailType="sendOutDate"
                detailValue={
                  t('common:intlDateFormattedLongMonth', {
                    date: surveyResult.fillableFrom,
                  }) as string
                }
              />
            </Stack>

            <Stack flexDirection="column" flex={1}>
              <DetailRow
                detailType="doctor"
                detailValue={t('common:formattedNameFull', {
                  title: surveyResult.doctor?.title,
                  firstName: surveyResult.doctor?.firstName,
                  lastName: surveyResult.doctor?.lastName,
                })}
                variant="h6"
              />
              <DetailRow
                detailType="institution"
                detailValue={
                  surveyResult.doctor?.institutionToDoctor[0]?.institution
                    .name || ''
                }
              />
              <DetailRow
                detailType="fillOutDate"
                detailValue={
                  t('common:intlDateFormattedLongMonth', {
                    date: surveyResult.finishedAt,
                  }) as string
                }
              />
            </Stack>
          </Stack>
        )}

        <Box>
          <IconButton
            onClick={() => setHidePatientDetails(!hidePatientDetails)}
          >
            {hidePatientDetails ? (
              <VisibilityIcon fontSize="large" />
            ) : (
              <VisibilityOffIcon fontSize="large" />
            )}
          </IconButton>
        </Box>
      </Stack>
    </Card>
  )
}
