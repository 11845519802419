import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { EmptyState } from '../../../../common/components/EmptyState'
import { useEesztTokenState } from '../../../../common/hooks/eeszt/useEesztTokenState'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../common/hooks/useUserType'
import { UserType } from '../../../../models/graphqlTypes'
import { TreatmentEESZTWarning } from './TreatmentEESZTWarning'
import { AddTreatmentButton } from './AddTreatmentButton'

type TreatmentsEmptyStateProps = {
  patientId: string
}

/**
 * Treatments empty state component
 * It is visible on the doctors patient screen
 * when the doctor's patient does not have any treatments
 */

const TreatmentsEmptyState: React.FC<
  React.PropsWithChildren<TreatmentsEmptyStateProps>
> = ({ patientId }) => {
  const { t } = useTranslation()
  const userType = useUserType()
  const isEesztTokenValid = useEesztTokenState()

  const [isAddTreatmentModal, toggleAddTreatmentModal] = useState(false)

  const isDoctor = userType === UserType.Doctor
  const message = isDoctor
    ? t('treatment:doctor.addNewTreatment.title')
    : t('treatment:assistant.noActiveTreatment')

  return (
    <>
      <EmptyState title={message}>
        {isDoctor && (
          <Box maxWidth={480}>
            <Box pb={4}>
              <Typography variant="body1">
                {t('treatment:doctor.addNewTreatment.description')}
              </Typography>
            </Box>

            {!isEesztTokenValid && (
              <Box mb={3}>
                <TreatmentEESZTWarning />
              </Box>
            )}

            <AddTreatmentButton
              upserting={isAddTreatmentModal}
              setUpserting={() => toggleAddTreatmentModal(!isAddTreatmentModal)}
              patientId={patientId}
            />
          </Box>
        )}
      </EmptyState>
    </>
  )
}

export { TreatmentsEmptyState }
