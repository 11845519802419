import React, { useState } from 'react'
import MedicationIcon from '@mui/icons-material/Medication'
import { Button } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks'
import { PatientListItemType } from '../../types/patientList.types'
import {
  AddTreatmentModal,
  AddTreatmentModalFormValues,
} from '../../../Treatments/components/AddTreatmentModal'
import { useTreatmentCreate } from '../../../Treatments/hooks/useTreatmentCreate'
import { useNotifications } from '../../../../../common/providers'
import {
  AddProtocolInput,
  BnoCodeChore,
  CreateTreatmentInput,
} from '../../../../../models/graphqlTypes'
import { ApolloError } from '@apollo/client'
import { AddProtocolModal } from '../../../Treatments/components/TreatmentPanel/AddProtocolModal'
import { useProtocolCreate } from '../../../Treatments/hooks/useProtocolCreate'

interface AddTreatmentToPatientsButtonProps {
  selectedPatients: PatientListItemType[]
  clearSelectedIds: () => void
}
export const AddTreatmentToPatientsButton: React.FC<
  AddTreatmentToPatientsButtonProps
> = ({ selectedPatients, clearSelectedIds }) => {
  const { t } = useTranslation()

  const { setSuccessToast, setErrorToast } = useNotifications()

  const [isTreatmentModalOpen, setIsTreatmentModalOpen] =
    useState<boolean>(false)

  const [bnoCodeIds, setBnoCodeIds] = useState<BnoCodeChore[]>([])
  const [createdTreatmentIds, setCreatedTreatmentIds] = useState<string[]>([])
  const isAddProtocolModalOpen = createdTreatmentIds.length > 0

  const [createTreatment, { loading: isTreatmentLoading }] = useTreatmentCreate(
    {}
  )

  const onTreatmentSave = async (formValues: AddTreatmentModalFormValues) => {
    try {
      const treatmentIds = await Promise.all(
        selectedPatients.map(async (patient) => {
          const treatmentData: CreateTreatmentInput = {
            patientId: patient.id,
            bnoCodeIds: formValues.bnoCodes.map((item) => item.id),
            customTreatmentTitle: {
              hu: formValues.title ?? '',
              en: formValues.title ?? '',
            },
            customTreatmentDescription: {
              hu: formValues.description ?? '',
              en: formValues.description ?? '',
            },
          }

          const { data } = await createTreatment({
            variables: {
              treatmentData,
            },
          })

          return data?.createTreatment.id ?? ''
        })
      )

      setBnoCodeIds(formValues.bnoCodes)
      setCreatedTreatmentIds(treatmentIds)
      setIsTreatmentModalOpen(false)
    } catch (err) {
      setErrorToast(err as ApolloError)
    }
  }

  const onCloseProtocolModal = () => {
    setCreatedTreatmentIds([])
    setBnoCodeIds([])
  }

  const [addProtocol, { loading: isProtocolLoading }] = useProtocolCreate({})

  const onProtocolSave = async (
    treatmentData: Omit<AddProtocolInput, 'treatmentId'>
  ) => {
    try {
      await Promise.all(
        createdTreatmentIds.map(async (treatmentId) => {
          await addProtocol({
            variables: {
              treatmentData: { ...treatmentData, treatmentId },
            },
          })
        })
      )

      onCloseProtocolModal()
      clearSelectedIds()
      setSuccessToast('appointment:creationMessage.success', {
        useFullKey: true,
      })
    } catch (err) {
      setErrorToast(err as ApolloError)
    }
  }

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="text"
        sx={{ paddingX: '16px' }}
        startIcon={<MedicationIcon />}
        onClick={() => setIsTreatmentModalOpen(true)}
      >
        {t('patients:actions.treatment')}
      </Button>

      {isTreatmentModalOpen && (
        <AddTreatmentModal
          isOpen={isTreatmentModalOpen}
          onClose={() => setIsTreatmentModalOpen(false)}
          onSave={onTreatmentSave}
          loading={isTreatmentLoading}
        />
      )}

      {isAddProtocolModalOpen && (
        <AddProtocolModal
          isOpen={isAddProtocolModalOpen}
          onSave={onProtocolSave}
          onClose={onCloseProtocolModal}
          loading={isProtocolLoading}
          treatmentBnoCodes={bnoCodeIds}
        />
      )}
    </>
  )
}
