import { gql } from '@apollo/client'

export const FRAGMENT_MULTILANGTEXT = gql`
  fragment multiLangText on MultiLangText {
    hu
    en
  }
`

export const FRAGMENT_OPTIONAL_MULTILANGTEXT = gql`
  fragment optionalMultiLangText on OptionalMultiLangText {
    hu
    en
  }
`

export const FRAGMENT_PROFESSION_CHORE = gql`
  fragment professionChore on Profession {
    id
    code
    name
  }
`

export const FRAGMENT_ROOM_CHORE = gql`
  fragment roomChore on Room {
    id
    eesztUnitId
    name {
      ...multiLangText
    }
    address {
      ...optionalMultiLangText
    }
    mapLink
    description {
      ...optionalMultiLangText
    }
    isActive
    neakCode
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_OPTIONAL_MULTILANGTEXT}
`

export const FRAGMENT_INSTITUTION_CHORE = gql`
  fragment institutionChore on Institution {
    id
    eesztId
    name
    addressCounty
    addressPostalCode
    addressCity
    addressAddress
    concatenatedAddress
    phoneNumber
    email
    website
    mapLink
    neak
    isActive
    isOwn
    rooms {
      ...roomChore
    }
  }
  ${FRAGMENT_ROOM_CHORE}
`

export const FRAGMENT_APPOINTMENT_CHORE = gql`
  fragment appointmentChore on Appointment {
    id
    proposedDate
    eesztBookingId
    isBetmenBooked
    appointmentInfo {
      id
    }
    institution {
      ...institutionChore
    }
    room {
      ...roomChore
    }
    status
  }
  ${FRAGMENT_ROOM_CHORE}
  ${FRAGMENT_INSTITUTION_CHORE}
`

export const FRAGMENT_SURVEY_CHORE = gql`
  fragment surveyChore on Survey {
    id
    fillableFrom
    fillableUntil
    surveySchema {
      id
      title
      description
      categories
      isGlobal
      status
      professions {
        ...professionChore
      }
    }
  }
  ${FRAGMENT_PROFESSION_CHORE}
`

export const FRAGMENT_BNO_CODE_CHORE = gql`
  fragment BnoCodeChore on BnoCode {
    id
    code
    title
  }
`

export const FRAGMENT_TREATMENT_SCHEMA_CHORE = gql`
  fragment treatmentSchemaChore on TreatmentSchema {
    id
    title {
      ...multiLangText
    }
    description {
      ...multiLangText
    }
    disabled
    url
    snowmedCode
    bnoCodes {
      ...BnoCodeChore
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_BNO_CODE_CHORE}
`

export const FRAGMENT_TREATMENT_CHORE = gql`
  fragment treatmentChore on Treatment {
    id
    status
    createdAt
    updatedAt
    comment
    doctor {
      id
    }
    title {
      ...multiLangText
    }
    description {
      ...multiLangText
    }
    bnoCodes {
      ...BnoCodeChore
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_BNO_CODE_CHORE}
`

export const FRAGMENT_PATIENT_CHORE = gql`
  fragment patientChore on PatientProfile {
    id
    firstName
    lastName
    title
    tajNumber
    phone
  }
`

export const FRAGMENT_NOTIFICATION_SETTINGS = gql`
  fragment notificationSettings on NotificationSettings {
    id
    allowEmailSending
    allowSmsSending
    type
  }
`

export const FRAGMENT_NOTIFICATION = gql`
  fragment notification on Notification {
    id
    type
    data
    isRead
    createdAt
    sentAt
  }
`

export const FRAGMENT_INSTITUTION_TO_DOCTOR = gql`
  fragment institutionToDoctor on InstitutionToDoctor {
    id
    assistant
    facebookId
    phone
    workingHours
    institution {
      id
      mapLink
      name
    }
  }
`

export const FRAGMENT_INSTITUTION_TO_DOCTOR_CHORE = gql`
  fragment institutionToDoctorChore on InstitutionToDoctor {
    id
    assistant
    facebookId
    phone
    workingHours
    institution {
      id
      mapLink
      name
    }
  }
`

export const FRAGMENT_USER_CHORE = gql`
  fragment userChore on User {
    id
    email
    userType
    language
    lastLogin
  }
`

// PROFILES
export const FRAGMENT_PROFILE_CHORE = gql`
  fragment profileChore on ProfileInterface {
    id
    title
    firstName
    lastName
    user {
      id
    }
  }
`

export const FRAGMENT_ASSISTANT_PROFILE_CHORE = gql`
  fragment assistantProfileChore on AssistantProfile {
    id
    firstName
    lastName
    title
    registrationNumber
    gender
    phone
    createdAt
    user {
      ...userChore
    }
  }
  ${FRAGMENT_USER_CHORE}
`

export const FRAGMENT_DOCTOR_LIST_DATA = gql`
  fragment doctorProfileForList on DoctorProfile {
    id
    firstName
    lastName
    title
    stampNumber
    userId
  }
`

export const FRAGMENT_DOCTOR_PROFILE_MINIMAL_DATA = gql`
  fragment doctorProfileMinimalForPatients on DoctorProfile {
    id
    title
    firstName
    lastName
    phone
    user {
      email
      id
    }
    assistants {
      ...assistantProfileChore
    }
  }
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
`

export const FRAGMENT_DOCTOR_PROFILE_FOR_PATIENTS = gql`
  fragment doctorProfileForPatients on DoctorProfile {
    id
    phone
    firstName
    lastName
    title
    professions {
      ...professionChore
    }
    institutionToDoctor {
      ...institutionToDoctorChore
    }
    user {
      id
      email
    }
    assistants {
      ...assistantProfileChore
    }
  }
  ${FRAGMENT_INSTITUTION_TO_DOCTOR_CHORE}
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
  ${FRAGMENT_PROFESSION_CHORE}
`

export const FRAGMENT_APPOINTMENT_INFO = gql`
  fragment appointmentInfoChore on AppointmentInfo {
    id
    patientTitle {
      ...multiLangText
    }
    doctorTodo {
      ...multiLangText
    }
    doctorTitle {
      ...multiLangText
    }
    goodToKnow {
      ...multiLangText
    }
    oeno
    professions {
      ...professionChore
    }
    userId
    eesztServiceId
    beforeTreatmentNote
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_PROFESSION_CHORE}
`

export const FRAGMENT_TREATMENT_SCHEMA = gql`
  fragment treatmentOptionsChore on TreatmentSchema {
    id
    description {
      ...multiLangText
    }
    title {
      ...multiLangText
    }
    professions {
      ...professionChore
    }
    institutions {
      id
      name
    }
    disabled
    user {
      id
      doctorProfile {
        title
        firstName
        lastName
      }
    }
    sharedWith {
      id
      doctorProfile {
        title
        firstName
        lastName
      }
    }
    url
    bnoCodes {
      ...BnoCodeChore
    }
    snowmedCode
    isGlobal
    optimalDuration
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_BNO_CODE_CHORE}
  ${FRAGMENT_PROFESSION_CHORE}
`

export const FRAGMENT_DOCTOR_PROFILE = gql`
  fragment doctorProfileChore on DoctorProfile {
    id
    phone
    title
    firstName
    lastName
    stampNumber
    userId
    professions {
      ...professionChore
    }
    user {
      id
      email
    }
    institutionToDoctor {
      ...institutionToDoctor
    }
    assistants {
      ...assistantProfileChore
    }
  }
  ${FRAGMENT_INSTITUTION_TO_DOCTOR}
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
  ${FRAGMENT_PROFESSION_CHORE}
`

export const FRAGMENT_DEPENDENCY_CHORE = gql`
  fragment dependencyChore on AppointmentDependency {
    id
    distance
    type
    isBroken
    isCustom
    fromId
    toId: appointmentId
  }
`

export const FRAGMENT_SURVEY_QUESTION_CHORE = gql`
  fragment surveyQuestionChore on SurveyQuestion {
    id
    title
    description
    type
    surveySchemaId
    isGlobal
    data {
      multipleChoice
      scaleData {
        options {
          value
          label
        }
      }
      fileUploadData {
        maxItemsCount
        maxFileSize
        validFileTypes
      }
    }
    mcSurveyQuestionChoices {
      id
      name
    }
    sclSurveyQuestionAspects {
      id
      name
    }
  }
`

export const FRAGMENT_SURVEY_SCHEMA_QUESTION_CHORE = gql`
  fragment surveySchemaQuestionChore on SurveySchemaQuestion {
    id
    required
    orderInSection
    section
    sectionName
    data {
      nextSectionInformation {
        choiceId
        choiceIndex
        nextSectionOrder
      }
    }
    surveyQuestion {
      ...surveyQuestionChore
    }
  }
  ${FRAGMENT_SURVEY_QUESTION_CHORE}
`

export const FRAGMENT_SURVEY_SCHEMA_CHORE = gql`
  fragment surveySchemaChore on SurveySchema {
    id
    title
    description
    patientDescription
    categories
    isGlobal
    status
    canBeDeleted
    referenceUrls {
      name
      url
    }
    snomedCode
    bnoCodes {
      ...BnoCodeChore
    }
    professions {
      ...professionChore
    }
    institutions {
      id
      name
    }
    sharedWithUsers {
      id
      doctorProfile {
        id
        title
        firstName
        lastName
        userId
      }
    }
  }
  ${FRAGMENT_BNO_CODE_CHORE}
  ${FRAGMENT_PROFESSION_CHORE}
`
export const FRAGMENT_SURVEY_ANSWER_CHORE = gql`
  fragment surveyAnswerChore on SurveyQuestionAnswer {
    mcQuestionAnswer {
      selectedChoiceIds
    }
    scaleQuestionAnswer {
      aspectAnswers {
        aspectId
        scaleValue
      }
    }
    simpleTextQuestionAnswer {
      text
    }
    fileUploadQuestionAnswer {
      documents {
        documentId
        fileName
      }
    }
  }
`

export const FRAGMENT_SURVEY_ITEM_DETAILS = gql`
  fragment surveyItemDetails on Survey {
    id
    status
    fillableFrom
    fillableUntil
    finishedAt
    doctor {
      id
      title
      firstName
      lastName
    }
    recipientProfile {
      title
      firstName
      lastName
      extraInfo
      user {
        userType
        id
      }
    }
  }
`

export const FRAGMENT_SURVEY_LIST_ITEM = gql`
  fragment surveyListItem on Survey {
    ...surveyItemDetails
    surveySchema {
      id
      title
      categories
    }
  }
  ${FRAGMENT_SURVEY_ITEM_DETAILS}
`
