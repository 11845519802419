import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  addProtocol,
  addProtocolVariables,
} from '../../../../models/graphqlTypes'
import { ADD_PROTOCOL } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<addProtocol, addProtocolVariables>

type Return = MutationTuple<addProtocol, addProtocolVariables>

export const useProtocolCreate = (options: Options = {}): Return => {
  return useMutation<addProtocol, addProtocolVariables>(ADD_PROTOCOL, options)
}
