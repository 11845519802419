import React, { useState } from 'react'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { Button } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks'
import { PatientListItemType } from '../../types/patientList.types'
import {
  AddSurveyFormValues,
  AddSurveyModal,
} from '../../../Treatments/components/Survey/AddSurveyModal'
import { useSendSurveyToUsers } from '../../../SurveyStore/hooks/useSendSurveyToUsers'
import { useNotifications } from '../../../../../common/providers'

interface SendSurveyToPatientsButtonProps {
  selectedPatients: PatientListItemType[]
  clearSelectedIds: () => void
}
export const SendSurveyToPatientsButton: React.FC<
  SendSurveyToPatientsButtonProps
> = ({ selectedPatients, clearSelectedIds }) => {
  const { t } = useTranslation()
  const { setSuccessToast, setErrorToast } = useNotifications()

  const [isSurveyModalOpen, setSurveyModalOpen] = useState<boolean>(false)

  const [sendSurveyToPatients] = useSendSurveyToUsers({
    onCompleted: () => {
      setSuccessToast('successSendSurvey')
      setSurveyModalOpen(false)
      clearSelectedIds()
    },
    onError: (e) => setErrorToast(e),
  })

  const handleSendSurvey = async (formValues: AddSurveyFormValues) => {
    sendSurveyToPatients({
      variables: {
        surveySchemaId: formValues.surveySchema?.id ?? '',
        userIds: selectedPatients.map((patient) => patient.user?.id ?? ''),
        fillableFrom: formValues.fillableFromDate,
        fillableUntil: formValues.fillableUntilDate,
      },
    })
  }

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="text"
        sx={{ paddingX: '16px' }}
        startIcon={<ListAltIcon />}
        onClick={() => setSurveyModalOpen(true)}
      >
        {t('patients:actions.survey')}
      </Button>

      {isSurveyModalOpen && (
        <AddSurveyModal
          params={{ isOpen: isSurveyModalOpen }}
          onSave={handleSendSurvey}
          onClose={() => setSurveyModalOpen(false)}
          title={t('patients:survey.sendTitle')}
          submitButtonText={t('patients:survey.sendButton')}
        />
      )}
    </>
  )
}
