import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  PaperProps,
} from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SaveButton } from '../../../../../common/components/SaveButton'
import { DoctorSelector } from '../../../../../common/components/selectors/DoctorSelector'
import { InstitutionSelector } from '../../../../../common/components/selectors/InstitutionSelector'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useNotifications } from '../../../../../common/providers/NotificationProvider'
import {
  doctorProfileChore,
  institutionChore,
} from '../../../../../models/graphqlTypes'
import { useSurveySchemaShare } from '../../hooks/useSurveySchemaShare'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'

type FormValues = {
  institutions: institutionChore[]
  sharedWithUsers: (doctorProfileChore | null)[]
}

interface Props {
  surveySchema: SurveySchemaWithoutUser
  isOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ShareSurveyModal: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  isOpen,
  setIsModalOpen,
}) => {
  const { t } = useTranslation()
  const { setSuccessToast, setErrorToast } = useNotifications()

  const formMethods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      institutions: surveySchema.institutions,
      sharedWithUsers: surveySchema.sharedWithUsers.map(
        (user) => user.doctorProfile
      ),
    },
  })

  const { handleSubmit } = formMethods

  const closeModal = () => setIsModalOpen(false)

  const [shareSurveySchema] = useSurveySchemaShare()

  const onSubmit = handleSubmit(({ institutions, sharedWithUsers }) => {
    shareSurveySchema({
      variables: {
        shareSurveySchemaInput: {
          surveySchemaId: surveySchema.id,
          institutionIds: institutions.map(({ id }) => id),
          sharedWithUserIds: sharedWithUsers
            .map((user) => user?.userId)
            .filter((x) => x) as string[],
        },
      },
      refetchQueries: ['getSurveys'],
      onCompleted: () => {
        setSuccessToast('successShareSurvey')
        closeModal()
      },
      onError: (e) => setErrorToast(e),
    })
  })

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        PaperProps={{ component: 'form' } as Partial<PaperProps<'div'>>}
      >
        <DialogTitle>{t('survey:share.title')}</DialogTitle>

        <DialogContent sx={{ width: { sm: 400 } }}>
          <DoctorSelector
            label={t('treatment:protocol.shareWithDoctors')}
            name="sharedWithUsers"
            disabled={surveySchema.isGlobal}
            excludeOwnProfile={true}
          />

          <InstitutionSelector
            label={t('survey:share.institutions')}
            name="institutions"
            disabled={surveySchema.isGlobal}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <SaveButton
            size="medium"
            text={t('common:save')}
            onClick={onSubmit}
          />
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}
