import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

import { Close as CloseIcon } from '@mui/icons-material'
import { useTranslation, useUserType } from '../../hooks'
import {
  professionChore,
  SurveyCategoryEnum,
  SurveyDoneStatusEnum,
  SurveyFilterForAssistant,
  SurveyFilterForDoctor,
  UserType,
} from '../../../models/graphqlTypes'
import { FILTER_PANEL_WIDTH } from '../../../config'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { hasActiveFilter } from '../../../utils/hasActiveFilter'
import { Accordion, AccordionDetails, AccordionSummary } from '../Accordion'
import { ResetOneFilterButton } from '../FilterPanelButtons/ResetOneFilterButton'
import { ProfessionSelector } from '../selectors/ProfessionSelector'
import { MultiSelector } from '../selectors/MultiSelector'
import {
  AssistantDoctor,
  useAssistantDoctors,
} from '../../../views/assistant/hooks/useAssistantDoctors'

type SurveyFiltersForm = {
  statuses: Partial<Record<SurveyDoneStatusEnum, boolean>>
  categories: Partial<Record<SurveyCategoryEnum, boolean>>
  professions: professionChore[]
  doctors?: AssistantDoctor[]
}

interface DoctorSurveyFiltersProps {
  isFilterPanelOpen: boolean
  handleFilterClose: () => void
  filterInput: SurveyFilterForAssistant
  setFilterInput: Dispatch<SetStateAction<SurveyFilterForAssistant>>
}
export const DoctorSurveyFilters: React.FC<
  React.PropsWithChildren<DoctorSurveyFiltersProps>
> = ({ isFilterPanelOpen, handleFilterClose, filterInput, setFilterInput }) => {
  const { t } = useTranslation('patients')

  const isAssistant = useUserType() === UserType.Assistant
  const { doctors, loading: isDoctorsLoading } = useAssistantDoctors()

  const formMethods = useForm<SurveyFiltersForm>({
    defaultValues: {
      professions: [],
      doctors: [],
    },
  })

  const { watch, reset, control, setValue } = formMethods

  const clearFilters = useCallback(() => {
    setFilterInput({})
    reset()
  }, [reset, setFilterInput])

  useEffect(() => {
    const { unsubscribe } = watch(async (value) => {
      const newFilter: SurveyFilterForAssistant = {
        statuses: Object.entries(value.statuses ?? {})
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, isChecked]) => isChecked)
          .map(([status]) => status as SurveyDoneStatusEnum),
        categories: Object.entries(value.categories ?? {})
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, isChecked]) => isChecked)
          .map(([status]) => status as SurveyCategoryEnum),
        professionIds:
          value.professions?.map((profession) => profession?.id ?? '') ?? [],
      }
      if (isAssistant) {
        newFilter['doctorIds'] =
          value.doctors?.map((doctor) => doctor?.doctor?.id ?? '') ?? []
      }
      setFilterInput((prevInput: SurveyFilterForDoctor) => {
        return {
          ...prevInput,
          ...newFilter,
        }
      })
    })
    return () => {
      unsubscribe()
      clearFilters()
    }
  }, [clearFilters, isAssistant, setFilterInput, watch])

  return (
    <Collapse in={isFilterPanelOpen} orientation="horizontal">
      <FormProvider {...formMethods}>
        <Stack
          sx={{
            width: FILTER_PANEL_WIDTH,
            height: '100%',
            backgroundColor: 'white',
          }}
          py={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            pl={2}
            pr={1}
          >
            <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
              {t('filters.title')}
            </Typography>

            {hasActiveFilter(filterInput) && (
              <Button
                size="small"
                color="primary"
                variant="text"
                onClick={clearFilters}
                sx={{ marginLeft: 'auto', paddingX: '4px' }}
              >
                {t('filters.reset')}
              </Button>
            )}
            <IconButton onClick={handleFilterClose} size="large">
              <CloseIcon />
            </IconButton>
          </Stack>

          <Stack px={1} sx={{ gap: '16px' }}>
            {isAssistant && (
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <Typography variant="body2">{t('filters.doctor')}</Typography>
                  {(filterInput.doctorIds?.length ?? 0) > 0 && (
                    <ResetOneFilterButton
                      onClick={() => setValue('doctors', [])}
                    />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <MultiSelector
                    name="doctors"
                    isOptionsLoading={isDoctorsLoading}
                    options={doctors || []}
                    getOptionLabel={(option) =>
                      t('common:formattedNameFull', {
                        title: option.doctor.title,
                        firstName: option.doctor.firstName,
                        lastName: option.doctor.lastName,
                      })
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('survey:status.title')}
                </Typography>
                {(filterInput.statuses?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => {
                      setValue('statuses', {})
                    }}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  {Object.values(SurveyDoneStatusEnum).map((status) => (
                    <Controller
                      key={status}
                      render={({ field }) => (
                        <FormControlLabel
                          label={t(`survey:status.${status.toLowerCase()}`)}
                          control={
                            <Checkbox
                              {...field}
                              checked={!!watch(`statuses.${status}`)}
                            />
                          }
                        />
                      )}
                      name={`statuses.${status}`}
                      control={control}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('survey:surveys.category')}
                </Typography>
                {(filterInput.categories?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => {
                      setValue('categories', {})
                    }}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  {Object.values(SurveyCategoryEnum).map((category) => (
                    <Controller
                      key={category}
                      render={({ field }) => (
                        <FormControlLabel
                          label={t(
                            `survey:survey.type.${category.toLowerCase()}`
                          )}
                          control={
                            <Checkbox
                              {...field}
                              checked={!!watch(`categories.${category}`)}
                            />
                          }
                        />
                      )}
                      name={`categories.${category}`}
                      control={control}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('survey:surveys.professions')}
                </Typography>
                {(filterInput.professionIds?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => {
                      setValue('professions', [])
                    }}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  <ProfessionSelector name="professions" />
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </FormProvider>
    </Collapse>
  )
}
