import { Button } from '@mui/material'
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'
import React, { Dispatch, SetStateAction } from 'react'
import {
  CreateTreatmentInput,
  treatmentChore,
  UpdateTreatmentInput,
} from '../../../../models/graphqlTypes'
import { useTreatmentCreate } from '../hooks/useTreatmentCreate'
import { useHistory } from 'react-router-dom'
import { useTreatmentUpdate } from '../hooks/useTreatmentUpdate'
import { FRAGMENT_TREATMENT_CHORE } from '../../../../operations'
import {
  AddTreatmentModal,
  AddTreatmentModalFormValues,
} from './AddTreatmentModal'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../common/hooks'

interface AddTreatmentButtonProps {
  upserting: treatmentChore | boolean
  setUpserting: Dispatch<SetStateAction<treatmentChore | boolean>>
  patientId: string
}
export const AddTreatmentButton: React.FC<AddTreatmentButtonProps> = ({
  upserting,
  setUpserting,
  patientId,
}) => {
  const treatment = typeof upserting === 'boolean' ? undefined : upserting

  const { t } = useTranslation()
  const history = useHistory()
  const selectedLanguage = useSelectedLanguage()

  const [createTreatment, { loading }] = useTreatmentCreate({
    onCompleted: (data) => {
      history.push(`/doctor/patients/${patientId}/${data.createTreatment.id}`)
    },
  })

  const [updateTreatment] = useTreatmentUpdate({
    update(cache, { data }) {
      if (!data) {
        return
      }
      cache.modify({
        id: cache.identify({ __typename: 'PatientProfile', id: patientId }),
        fields: {
          treatments(existingTreatmentRefs = []) {
            const newTreatmentRef = cache.writeFragment({
              data: data.updateTreatment,
              fragment: FRAGMENT_TREATMENT_CHORE,
              fragmentName: 'treatmentChore',
            })
            return [...existingTreatmentRefs, newTreatmentRef]
          },
        },
      })
    },
    onCompleted: () => setUpserting(false),
  })

  const onSubmit = (formValues: AddTreatmentModalFormValues) => {
    if (!treatment) {
      const treatmentData: CreateTreatmentInput = {
        patientId,
        bnoCodeIds: formValues.bnoCodes.map((item) => item.id),
        customTreatmentTitle: {
          hu: formValues.title ?? '',
          en: formValues.title ?? '',
        },
        customTreatmentDescription: {
          hu: formValues.description ?? '',
          en: formValues.description ?? '',
        },
      }

      return createTreatment({
        variables: {
          treatmentData,
        },
      })
    }

    const treatmentData: Omit<UpdateTreatmentInput, 'status'> = {
      treatmentId: treatment.id,
      bnoCodeIds: formValues.bnoCodes.map((item) => item.id),
    }

    if (formValues.title) {
      treatmentData.title = {
        en: treatment.title.en,
        hu: treatment.title.hu,
        [selectedLanguage]: formValues.title,
      }
    }
    if (formValues.description) {
      treatmentData.description = {
        en: treatment.description?.en ?? '',
        hu: treatment.description?.hu ?? '',
        [selectedLanguage]: formValues.description,
      }
    }

    return updateTreatment({
      variables: { treatmentData },
    })
  }

  return (
    <>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        size="small"
        color="primary"
        onClick={() => setUpserting(true)}
        sx={{ marginLeft: 'auto' }}
      >
        {t('treatment:doctor.addTreatmentProposal')}
      </Button>

      <AddTreatmentModal
        isOpen={Boolean(upserting)}
        onClose={() => setUpserting(false)}
        onSave={onSubmit}
        treatment={typeof upserting === 'boolean' ? undefined : upserting}
        loading={loading}
      />
    </>
  )
}
