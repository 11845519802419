import React, { FC, useMemo, useState, useRef, useCallback, JSX } from 'react'
import {
  Box,
  Fade,
  IconButton,
  Typography,
  Collapse,
  Checkbox,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import clsx from 'clsx'
import {
  Apps,
  CalendarTodayOutlined,
  Edit,
  ExpandMore,
} from '@mui/icons-material'
import { Draggable } from 'react-beautiful-dnd'
import { DayPickerModal } from '../DayPickerModal'
import { isNumber } from 'lodash'
import { LocalSchedule } from '../treatmentSchemaSchedule.types'
import { AppointmentAdminModal } from '../../../../admin/components/AppointmentAdminModal'

const useStyles = makeStyles((theme) => ({
  container: {
    borderWidth: 2,
    borderColor: theme.palette.grey[200],
    background: 'white',
    '&:hover': {
      background: theme.palette.grey[50],
    },
  },
  error: {
    borderColor: theme.palette.error.light,
  },
  dayError: {
    color: theme.palette.error.light,
  },
  isDragging: {
    background: theme.palette.grey[50],
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  detailsContainer: {
    position: 'relative',
    borderTop: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
  },
  editDetails: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

interface Props {
  index: number
  id: string
  schedule: LocalSchedule
  isEditable: boolean
  selected: boolean
  treatmentSchemaId: string
  handleSelect: () => void
  onDayChanged: (id: string, day?: number) => void
  addNewSchedule: (appointmentInfoId: string) => void
}

export const TreatmentSchemaScheduleDraggableListItem: FC<
  React.PropsWithChildren<Props>
> = ({
  schedule,
  index,
  id,
  isEditable,
  selected,
  treatmentSchemaId,
  onDayChanged: onDayChangedProp,
  handleSelect,
}) => {
  const {
    appointmentInfo,
    doctorTitle,
    customDays,
    dayError,
    dayConstraints,
    dependencies,
    dependents,
  } = schedule

  const classes = useStyles()
  const { t } = useTranslation()

  const selectedLanguage = useSelectedLanguage()

  const anchorEl = useRef(null)
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false)

  const [expanded, setIsExpanded] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const typeText = doctorTitle?.[selectedLanguage]

  const isDayDeletable = useMemo(() => {
    if (index === 0) {
      return false
    }
    const isInDependency = dependencies.length || dependents.length
    if (isInDependency) {
      return isNumber(customDays) && customDays !== dayConstraints.minDay
    }
    return true
  }, [
    customDays,
    dayConstraints.minDay,
    dependencies.length,
    dependents.length,
    index,
  ])

  const toggleDayPicker = useCallback(() => {
    setIsDayPickerOpen((wasOpen) => !wasOpen)
  }, [])

  const closeDayPicker = useCallback(() => {
    setIsDayPickerOpen(false)
  }, [])

  const onDayChanged = useCallback(
    (newDay?: number) => {
      onDayChangedProp(id, newDay)
      setIsDayPickerOpen(false)
    },
    [onDayChangedProp, id]
  )

  const onExpand = useCallback(() => {
    setIsExpanded((oldExpanded) => !oldExpanded)
  }, [])

  const toggleEdit = useCallback(() => {
    setIsEditModalOpen((prevState) => !prevState)
  }, [])

  const DayChooserComponent = useMemo<JSX.Element>(() => {
    const dayText = isNumber(customDays)
      ? t('common:formattedDay', {
          day: customDays + 1,
        })
      : null
    return (
      <Box
        width={110}
        display="flex"
        alignItems={'center'}
        justifyContent={'flex-start'}
        mr={1}
        ref={anchorEl}
        data-cy="TreatmentSchemaScheduleDraggableListItem"
      >
        {!!dayText && (
          <Typography
            variant="subtitle1"
            ml={1}
            className={dayError ? classes.dayError : ''}
          >
            {dayText}
          </Typography>
        )}
        {index !== 0 && isEditable && (
          <IconButton size="large" onClick={toggleDayPicker}>
            {!!dayText ? index !== 0 && <Edit /> : <CalendarTodayOutlined />}
          </IconButton>
        )}
      </Box>
    )
  }, [
    customDays,
    t,
    dayError,
    classes.dayError,
    index,
    isEditable,
    toggleDayPicker,
  ])

  const CollapsibleDetailsComponent = useMemo(() => {
    const details = [
      {
        title: `${t('appointment:admin.appointmentInfoName')} (${t(
          'common:patient'
        )})`,
        content: appointmentInfo?.patientTitle?.[selectedLanguage],
      },
      {
        title: t('appointment:admin.appointmentInfoProfessions'),
        content: appointmentInfo?.professions
          ?.map((profession) => profession.name)
          .join(', '),
      },
      {
        title: t('common:doctorTodo'),
        content: appointmentInfo?.doctorTodo?.[selectedLanguage],
      },
      {
        title: t('appointment:goodToKnowAndNotes'),
        content: appointmentInfo?.goodToKnow?.[selectedLanguage],
      },
      {
        noTitle: true,
        content:
          appointmentInfo?.beforeTreatmentNote &&
          t('appointment:admin.dontEatOrDrink'),
      },
    ].filter((detail) => detail.content)
    return (
      <Collapse in={expanded} timeout={200} unmountOnExit>
        <Box px={6} className={classes.detailsContainer}>
          {isEditable && (
            <IconButton
              size="large"
              onClick={toggleEdit}
              className={classes.editDetails}
            >
              <Edit />
            </IconButton>
          )}
          {details.map((item) => (
            <Box
              key={`${item.title}_${item.content}`}
              my={1}
              px={4.5}
              display="flex"
              alignContent="center"
              flexDirection="column"
            >
              {!item.noTitle && (
                <Typography variant="body2">{item.title}:</Typography>
              )}
              <Typography variant="subtitle1">{item.content}</Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    )
  }, [
    t,
    appointmentInfo?.patientTitle,
    appointmentInfo?.professions,
    appointmentInfo?.doctorTodo,
    appointmentInfo?.goodToKnow,
    appointmentInfo?.beforeTreatmentNote,
    selectedLanguage,
    expanded,
    classes.detailsContainer,
    classes.editDetails,
    isEditable,
    toggleEdit,
  ])

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Fade
          in={true}
          timeout={750}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Box display="flex" flexDirection="row">
            <Box
              className={clsx(classes.container, {
                [classes.isDragging]: snapshot.isDragging,
                [classes.error]: !!dayError,
              })}
              mb={1}
              border={1}
              borderRadius={1.3}
              flex={1}
            >
              <Box id={`arrow-row-${id}`} display="flex" alignItems="center">
                <IconButton
                  size="large"
                  {...provided.dragHandleProps}
                  disabled={!isEditable}
                >
                  <Apps />
                </IconButton>
                {isEditable && (
                  <Checkbox
                    checked={selected}
                    onChange={handleSelect}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                )}
                {DayChooserComponent}
                <Box flex={1}>
                  <Typography variant="body2">{typeText}</Typography>
                </Box>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={onExpand}
                  size="large"
                >
                  <ExpandMore />
                </IconButton>
              </Box>
              {CollapsibleDetailsComponent}
            </Box>
            <DayPickerModal
              open={isDayPickerOpen}
              onClose={closeDayPicker}
              anchorEl={anchorEl?.current}
              selectedDay={customDays}
              minValue={dayConstraints.minDay}
              maxValue={dayConstraints.maxDay}
              onDayChanged={onDayChanged}
              isDayDeletable={isDayDeletable}
            />
            <AppointmentAdminModal
              isOpen={isEditModalOpen}
              scheduleId={schedule.id}
              treatmentSchemaId={treatmentSchemaId}
              setIsOpen={toggleEdit}
              selectedAppointmentInfo={appointmentInfo}
            />
          </Box>
        </Fade>
      )}
    </Draggable>
  )
}
